import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Expectations are the raw material of disappointments. I came to this conclusion
as I was driving one night, completely shattered that a recent spiritual
expectation remained unmet. It sounds silly but there were many times I
confidently sought an answer in prayer full of expectation I would receive an
answer. I would sometimes go into the outdoors and "wrestle" pray until I felt
discouraged, or I'd sit in my car outside my families house believing an answer
would come if not only because my desperate situation merited one from the
Father I heard learned loved me. When that answer didn't come and I expected
that it would, I would usually fall into one of two traps:`}</p>
    <ol>
      <li parentName="ol">{`The fact that I didn't receive an answer must mean there is something
"wrong" or "broken" in me. I must be too incompetent, too sinful, too wicked
or otherwise broken to receive the answer that I desperately need. This
particular trap was magnified by my battles with mental illness, a perpetual
prideful shame-based worldview and a religious culture that involved a great
deal of the very types of "expectation" that is so problematic. If God loves
me, and is unable to get this answer through to me, am I too broken for even
God to work with and fix?`}</li>
      <li parentName="ol">{`God must not really love me, if He did, surely He would understand how dire
my situation is and therefore provide a timely answer. And lastly, If all I
hear is radio silence on the other end; then maybe there is no God on the
other end. Perhaps the atheists are right & this is all for not anyways.
This thought was, and still is, a horror to me. Where then can I find hope?
Nihilism is one of the most painful of all abysses to consider.`}</li>
    </ol>
    <p>{`So back to that night, where I arrived at my conclusion about expectations as
"setups". I was driving feeling particularly discouraged when the thought
occurred to me...`}</p>
    <blockquote>
      <p parentName="blockquote">{`The problem is not my reality, the problem is my expectations. If I had no
such expectation about receiving divine answers in a particular way I could
avoid all the disappointment when they didn't come as I expected them too.`}</p>
    </blockquote>
    <p>{`This thought brought me a great deal of peace & served as a very useful way of
thinking about other relationships... So long as I didn't have expectations
about a such and such person being there for me in a such and such way, I could
avoid the shattering sense of abandonment when they weren't. More than that, I
could appreciate when someone did follow through, or when I did receive
spiritual answers & guidance. Expectation certainly has a way of robbing one of
gratitude because expectations are often symptoms of entitlement. If I expect
three meals a day, I am much less likely to notice and appreciate receiving one.
You can see this in the husband & wife, married 40 years, who have certain
expectations about the relationship; once the expectations become habitual,
entitlement takes shape... The lack of dinner on the table becomes the reason
for anger or even resentment when perhaps those first meals as newlyweds were
like sacred interdependent introductions to the wonderful life they knew they
had before them.`}</p>
    <p>{`You see it in children too, having everything given them, expectations are
formed & a great deal of the parent's sacrifice goes unnoticed until later in
life; maybe even until that child has their own children. And upon sacrificing,
loving and giving everything to their children they begin to fully comprehend
and appreciate the devotion given to them by their own parents.`}</p>
    <p>{`With all its strengths, it wasn't long before I ran into a particular limitation
with this way of thinking about expectations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If I have no expectations, what reason do I have to do anything? What role
does `}<em parentName="p">{`faith`}</em>{` play if, instead of believing in salvation, one prefers to
surrender the idea of salvation altogether? If I have no expectation or hope
for a better life what motivation do I have to pursue it? What difference does
God's existence make if I omit any expectation from Him?`}</p>
    </blockquote>
    <p>{`These questions and others like them kept coming up but I knew that there was
some truth in my current way of thinking about expectations but there was a
certain "life" lost, a certain fire or drive that had been starved. Which brings
me to a further insight into the nature of expectations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The answer is not to obliterate your expectations, the answer is to raise them
beyond the shallowness of your own thinking & surrender completely in trust
and devotion to the Father.`}</p>
    </blockquote>
    <p>{`Rather than obliterating all expectations and settling for a "controlled"
reality to avoid disappointment & discouragement, I believe adopting more
humility is warranted. If I really believed that God was goodness & love itself
then the problem wasn't the fact that I had expectations. The problem was the
particular expectations I had, and my response, to abandon all expectations; was
in a sense a vote against myself or a vote against God's goodness and more than
likely some combination of the two. As I learned and, continue to learn, more
about `}<a parentName="p" {...{
        "href": "/thoughts/gods-love"
      }}>{`God's Love`}</a>{` I realized that expectations should be
set in a context of complete trust and submission to the will of God.`}</p>
    <p>{`If my expectations are not met it can serve as feedback that there is some
information, insight or truth I am missing. I am not yet the type of being that
can comprehend and see the purpose in this current strife and only obedience and
submission to the commandments and the will of God can make me the type of
person that will understand it. Or perhaps there is something about reality that
I believe to be true, that isn't?`}</p>
    <p>{`I think more often than not, it is because our expectations are too low. Thank
God that His goodness and love are not limited by our expectations of Him. He is
infinitely more expansive, loving, kind & good than our mere expectations could
paint Him. We are only so capable of seeing Him & comprehending Him; as we
become more like Him we see Him more & see more like Him and as He transforms us
we realize how shallow our lesser self is and that through His eyes the meeting
of our expectations would often serve only as a limitation & prison to us.`}</p>
    <p>{`As we trust & love God we come to see that our expectations need not be
obliterated but rather lifted and submitted to Him. When our expectations,
earnestly sought, remain unmet I believe we have reason to thank God for that,
not to shake our fist at Him or ourselves but to follow Him with more devotion
as He leads us to places where our expectations are vastly exceeded and in so
doing our gratitude and love for Him expand, as they expand we are capable of
receiving all the more of what He intends for us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      